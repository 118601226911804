<script>
import Helper from "@/utils/helper";
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: { isMoney: Boolean, unit: String, isFloat: Boolean, isClickable: Boolean },
  computed: {
    options() {
      let value = {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
        // legend: {
        //   labels: {
        //     usePointStyle: true,
        //     boxHeight: 8,
        //     boxWidth: 8,
        //   },
        // },
        responsive: true,
        maintainAspectRatio: false,
      };

      if (this.isClickable) {
        value.onClick = (e, array) => {
          if (!array.length) return;

          const chart = array[0]._chart;
          const index = array[0]._index;

          this.$emit('monthSelected', chart.data.labels[index])
        }

        value.onHover = (e, array) => {
          if (!array.length) return;

          e.target.style.cursor = array[0] ? 'pointer' : 'default';
        }
      }

      if (this.unit) {
        value.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return Helper.utils.formatNumber(value);
        };

        const unit = this.unit;

        value.tooltips = {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }

              label += Helper.utils.formatNumber(tooltipItem.yLabel);

              return `${label} ${unit}`;
            },
          },
        };
      } else if (this.isMoney) {
        value.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return Helper.utils.formatMoney(value);
        };

        value.tooltips = {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }

              label += Helper.utils.formatMoney(tooltipItem.yLabel);

              return label;
            },
          },
        };
      } else if (this.isFloat) {
        value.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return Helper.utils.formatNumber(value);
        };

        value.tooltips = {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }

              label += Helper.utils.formatNumber(tooltipItem.yLabel, 2);

              return label;
            },
          },
        };
      }

      return value;
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
