<template>
  <div class="dashboard container-fluid pb-3">
    <div class="row px-3">
      <div class="col-12 col-lg-8 col-md-6 p-2">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-default-dark text-center">
              Operation Sites
            </h5>
            <div class="card-height">
              <div class="text-center d-flex flex-wrap justify-content-center align-items-end">
                <div
                  class="px-4 py-3 m-1 align-self-stretch d-flex flex-column justify-content-center bg-secondary rounded-lg text-dark line-height-xs">
                  <p class="fs-xl mb-0">
                    <!-- {{ sites.length }} -->
                    35
                  </p>
                  <span class="badge badge-secondary badge-pill text-dark px-3 py-2 text-uppercase">
                    <h5 class="mb-0">Overall</h5>
                  </span>
                </div>

                <div>
                  <div class="py-2 px-4 m-1 bg-default-dark rounded-lg text-default-dark line-height-md"
                    v-for="region in siteRegions" :key="`region-count-${region.name}`">
                    <p class="fs-lg mb-0">
                      {{ region.count }}
                    </p>
                    <span class="badge badge-secondary badge-pill text-dark px-3 py-2 mb-2 text-uppercase">
                      {{ region.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row row-cols-2 mt-5">
                <div class="col d-flex justify-content-between align-items-center line-height-md mb-2"
                  v-for="type in siteTypes" :key="type.name">
                  <span class="font-weight-medium mr-2" v-html="type.name"></span>
                  <span class="font-weight-bold text-default-dark">{{
                    type.count
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 p-2">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-center text-default-dark">
              Live Streaming
            </h5>
            <div class="position-relative" style="height: calc(100% - 19px - 0.75rem)">
              <video @click.prevent id="liveStream" muted playsinline class="videoPlayer"
                :class="{ invisible: !stream.isOnline }">
                <source id="mp4" type="video/mp4" />
              </video>
              <div v-if="!stream.isInitialized"
                class="h-100 w-100 position-absolute d-flex flex-column justify-content-center align-items-center bg-default-dark"
                style="top: 0">
                <i class="fas fa-video fa-2x mb-3" />
                <button class="btn btn-primary btn-sm" @click="initializeLiveStream()">
                  Click to view live stream
                </button>
              </div>
              <div v-else-if="stream.isInitialized && isLoadingStream"
                class="h-100 w-100 position-absolute d-flex flex-column justify-content-center align-items-center bg-default-dark"
                style="top: 0">
                <div class="spinner-border"></div>
                <p class="mb-0 mt-2 text-muted">Loading...</p>
              </div>
              <div v-else-if="stream.isInitialized && !stream.isOnline"
                class="h-100 w-100 position-absolute d-flex justify-content-center align-items-center bg-default-dark"
                style="top: 0">
                <p class="mb-0 h4 text-muted">Stream Offline</p>
              </div>
              <div v-else-if="stream.isOnline"
                class="py-2 px-3 position-absolute d-flex justify-content-center align-items-center badge badge-pill badge-primary"
                style="bottom: 1rem; left: 1rem">
                <i class="fas fa-broadcast-tower mr-2 blinking text-light" />
                <p class="mb-0 blinking text-light">Live</p>
              </div>
              <button class="btn btn-secondary btn-sm text-decoration-none position-absolute" v-if="stream.isOnline"
                style="bottom: 1rem; right: 1rem" @click="streamFullscreen">
                <i class="fas fa-expand mr-2" />
                Fullscreen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-2">
        <div class="card h-100">
          <div class="card-body d-flex flex-column align-items-center">
            <h5
              class="card-title text-default-dark text-center d-flex flex-column justify-content-center align-items-center">
              <div>Overall Sales <span class="text-default">({{ computedOverallValue }})</span></div>
              <select class="custom-select mt-3" v-model="selectedYear" style="width: 90px">
                <option :value="year" v-for="year in years.toReversed()">{{ year }}</option>
              </select>
            </h5>
            <div class="flex-fill w-100 d-flex justify-content-center align-items-center">
              <bar-chart :chart-data="computedOverallChart" class="w-100 h-100"
                v-if="computedOverallChart.datasets[0].data.length" is-float />
              <p class="text-muted mb-0 text-uppercase" v-else>No data available</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-2">
        <div class="card h-100">
          <div class="card-body d-flex flex-column align-items-center">
            <h5 class="card-title text-default-dark d-flex justify-content-between align-items-center w-100 px-2">
              Site Sales
              <button type="button"
                class="btn dropdown-toggle dropdown-toggle-split border border-default-dark bg-text-default-dark text-light no-focus"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
                {{ selectedSite?.name || 'Select site' }}&nbsp;
              </button>
              <div class="dropdown-menu">
                <div class="dropdown-item-text px-2">
                  <div class="form-group mb-0">
                    <input type="text" class="form-control no-focus w-100" id="searchSite" ref="searchSite"
                      placeholder="Type here to search" v-model="siteSearch" />
                  </div>
                </div>
                <div class="site-dropdown-items">
                  <button v-for="site in computedSites" :key="site.id" @click="siteSelected(site)"
                    class="dropdown-item px-3 py-2"
                    :class="{ 'text-warning': selectedSite?.site_code == site.site_code }">
                    {{ site.name }}
                  </button>
                  <div v-if="!isLoading && computedSites.length < 1" class="dropdown-item-text text-muted">
                    No site found
                  </div>
                  <div v-if="isLoading" class="dropdown-item-text text-muted">
                    <div class="spinner-border"></div>
                    <span class="ml-3">Loading sites...</span>
                  </div>
                </div>
              </div>
            </h5>
            <div class="row mx-0 w-100">
              <div class="col-12 col-lg-6 p-2">
                <div class="card h-100 border border-text-default-dark">
                  <div class="card-body d-flex flex-column align-items-center">
                    <h5 class="card-title text-default-dark d-flex justify-content-between align-items-center w-100">
                      <div>
                        {{ selectedYear }} Monthly <span class="text-default">({{ computedSiteMonthlyValue }})</span>
                      </div>
                      <small class="text-muted">Click on bar to view daily data</small>
                    </h5>
                    <div class="flex-fill w-100 d-flex justify-content-center align-items-center">
                      <bar-chart :chart-data="computedSiteMonthlyChart" class="w-100 h-100"
                        v-if="computedSiteMonthlyChart.datasets[0].data.length" is-clickable is-float
                        @monthSelected="monthSelected" />
                      <p class="text-muted mb-0 text-uppercase" v-else>No data available</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 p-2">
                <div class="card h-100 border-text-default-dark">
                  <div class="card-body d-flex flex-column align-items-center">
                    <h5 class="card-title text-default-dark">
                      {{ selectedMonth ? `${selectedYear} ${selectedMonth.fullName} Daily` : 'Daily' }}
                      <span class="text-default" v-if="selectedMonth">({{ computedSiteDailyValue }})</span>
                    </h5>
                    <div class="flex-fill w-100 d-flex justify-content-center align-items-center">
                      <bar-chart :chart-data="computedSiteDailyChart" class="w-100 h-100"
                        v-if="computedSiteDailyChart.datasets[0].data.length" is-float />
                      <p class="text-muted mb-0 text-uppercase" v-else-if="selectedMonth">No data available</p>
                      <p class="text-muted mb-0 text-uppercase" v-else>Click on monthly chart to view details</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import BarChart from "@/components/BarChart.vue";
import Spinner from "@/components/Spinner.vue";
import flvjs from "flv.js";
import moment from "moment";

const currentYear = new Date().getFullYear();

export default {
  data() {
    return {
      currentYear: currentYear,
      overallData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: `${currentYear} (MT)`,
            data: [],
            backgroundColor: "rgba(255, 206, 86, 0.2)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
        ],
      },
      monthlyData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: `${currentYear} (MT)`,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      },
      siteData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: "rgba(255, 206, 86, 0.2)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
        ],
      },
      monthList: [
        { id: 1, value: "01", name: "Jan", fullName: "January", noOfDays: 31 },
        { id: 2, value: "02", name: "Feb", fullName: "February", noOfDays: 0 },
        { id: 3, value: "03", name: "Mar", fullName: "March", noOfDays: 31 },
        { id: 4, value: "04", name: "Apr", fullName: "April", noOfDays: 30 },
        { id: 5, value: "05", name: "May", fullName: "May", noOfDays: 31 },
        { id: 6, value: "06", name: "Jun", fullName: "June", noOfDays: 30 },
        { id: 7, value: "07", name: "Jul", fullName: "July", noOfDays: 31 },
        { id: 8, value: "08", name: "Aug", fullName: "August", noOfDays: 31 },
        { id: 9, value: "09", name: "Sep", fullName: "September", noOfDays: 30 },
        { id: 10, value: "10", name: "Oct", fullName: "October", noOfDays: 31 },
        { id: 11, value: "11", name: "Nov", fullName: "November", noOfDays: 30 },
        { id: 12, value: "12", name: "Dec", fullName: "December", noOfDays: 31 },
      ],
      stream: {
        url: "https://playback.aisc.tv/live/selgem.flv",
        isOnline: false,
        isInitialized: false,
      },
      siteRegions: [],
      sites: [],
      siteTypes: [],
      flvPlayer: null,
      isLoadingStream: false,
      isLoading: true,
      siteSearch: "",
      years: [2022, 2023, 2024],
      selectedYear: null,
      selectedSite: null,
      selectedMonth: null,
      transactions: []
    };
  },
  components: {
    BarChart,
    Spinner,
  },
  watch: {
    selectedYear: {
      async handler() {
        this.selectedMonth = null;

        await this.getTransactions();
      }, immediate: true
    }
  },
  computed: {
    computedSites() {
      let sites = [...this.sites].filter(
        (site) => site.region?.name !== "Central Region"
      );

      if (this.siteSearch) {
        sites = sites.filter((site) =>
          site.name.toLowerCase().includes(this.siteSearch)
        );
      }

      return sites;
    },
    computedSiteMonthlyChart() {
      const _siteData = JSON.parse(JSON.stringify(this.monthlyData));

      _siteData.datasets[0].data = [];
      _siteData.datasets[0].hoverBackgroundColor = "rgba(0, 200, 127, 0.2)";
      _siteData.datasets[0].hoverBorderColor = "rgba(0, 200, 127, 1)";

      if (this.selectedSite) {
        const _transactions = this.transactions.find(transaction => transaction.site == this.selectedSite?.site_code)?.data || [];

        _siteData.datasets[0].data = this.monthList.map((month) => {
          return _transactions
            .filter(
              (transaction) => transaction.Tarikh.split('-')[1] == month.id
            )
            .reduce((total, transaction) => {
              return total + parseFloat(transaction.Berat)
            }, 0);
        });

        _siteData.datasets[0].label = `${this.selectedYear} (MT)`;

        _siteData.datasets[0].backgroundColor = this.monthList.map((month) => {
          return month.id == this.selectedMonth?.id ? "rgba(0, 200, 127, 0.2)" : "rgba(255, 206, 86, 0.2)";
        });

        _siteData.datasets[0].borderColor = this.monthList.map((month) => {
          return month.id == this.selectedMonth?.id ? "rgba(0, 200, 127, 1)" : "rgba(255, 206, 86, 1)";
        });
      }

      return _siteData;
    },
    computedSiteMonthlyValue() {
      if (this.selectedSite) {
        const _transactions = this.transactions.find(transaction => transaction.site == this.selectedSite?.site_code)?.data || [];

        return `${this.Helper.formatNumber(_transactions.reduce((total, transaction) => {
          return total + parseFloat(transaction.Berat)
        }, 0), 2)} MT`;
      }

      return "0 MT";
    },
    computedOverallChart() {
      const _overallData = JSON.parse(JSON.stringify(this.overallData));

      _overallData.datasets[0].data = [];

      const _transactions = this.transactions.map(transaction => transaction.data).flat() || [];

      _overallData.datasets[0].data = this.monthList.map((month) => {
        return _transactions
          .filter(
            (transaction) => transaction.Tarikh.split('-')[1] == month.id
          )
          .reduce((total, transaction) => {
            return total + parseFloat(transaction.Berat)
          }, 0);
      });

      _overallData.datasets[0].label = `${this.selectedYear} (MT)`;

      return _overallData;
    },
    computedOverallValue() {
      const _transactions = this.transactions.map(transaction => transaction.data).flat() || [];

      return `${this.Helper.formatNumber(_transactions.reduce((total, transaction) => {
        return total + parseFloat(transaction.Berat)
      }, 0), 2)} MT`;
    },
    computedSiteDailyChart() {
      const _siteData = JSON.parse(JSON.stringify(this.siteData));

      if (this.selectedSite && this.selectedMonth) {
        const _transactions = this.transactions.find(
          transaction => transaction.site == this.selectedSite?.site_code
        )?.data.filter(transaction => transaction.Tarikh.split("-")[1] == this.selectedMonth.value) || [];

        _siteData.labels = [];
        _siteData.datasets[0].data = [];

        for (let index = 1; index <= this.selectedMonth.noOfDays; index++) {
          const dayNumber = index < 10 ? "0".concat(index.toString()) : index.toString();

          _siteData.labels.push(dayNumber);
          _siteData.datasets[0].data.push(
            _transactions.find(transaction => transaction.Tarikh == `${this.selectedYear}-${this.selectedMonth.value}-${dayNumber}`)?.Berat || 0
          );
        }

        _siteData.datasets[0].label = `${this.selectedMonth.fullName} (MT)`;
      }

      return _siteData;
    },
    computedSiteDailyValue() {
      if (this.selectedSite && this.selectedMonth) {
        const _transactions = this.transactions.find(
          transaction => transaction.site == this.selectedSite?.site_code
        )?.data.filter(transaction => transaction.Tarikh.split("-")[1] == this.selectedMonth.value) || [];

        return `${this.Helper.formatNumber(_transactions.reduce((total, transaction) => {
          return total + parseFloat(transaction.Berat)
        }, 0), 2)} MT`;
      }

      return "0 MT";
    }
  },
  methods: {
    monthSelected(e) {
      if (this.selectedMonth?.name == e) {
        this.selectedMonth = null;
      } else {
        this.selectedMonth = this.monthList.find(month => month.name == e);
      }
    },
    siteSelected(site) {
      this.selectedSite = site;
      this.siteSearch = "";

      this.selectedMonth = null;
    },
    populateCharts() {
      const _transactions = this.transactions.map(transaction => transaction.data).flat() || [];

      this.overallData.datasets[0].label = `${this.selectedYear} (MT)`;

      this.overallData.datasets[0].data = this.monthList.map((month) => {
        return _transactions
          .filter(
            (transaction) => transaction.Tarikh.split('-')[1] == month.id
          )
          .reduce((total, transaction) => {
            return total + parseFloat(transaction.Berat)
          }, 0);
      });
    },
    async getRegions() {
      this.isLoading = true;

      const [call, err] = await this.Helper.handle(
        this.API.get("regions?name_ne=Central+Region")
      );

      if (!err && call.status == 200) {
        // // Disable kejap
        // this.siteRegions = call.data.map((region) => {
        //   return {
        //     id: region.id,
        //     name: region.name?.replace(" Region", ""),
        //     count: 0,
        //   };
        // });

        this.siteRegions = call.data.map((region) => {
          return {
            id: region.id,
            name: region.name?.replace(" Region", ""),
            count: region.name?.includes("Northern") ? 15 : 20,
          };
        });
      }

      return;
    },
    async getSiteTypes() {
      this.isLoading = true;

      // // Disable kejap
      // const [call, err] = await this.Helper.handle(this.API.get("site-types"));

      // if (!err && call.status == 200) {
      //   this.siteTypes = call.data.map((type) => {
      //     return {
      //       id: type.id,
      //       name: type.name,
      //       count: 0,
      //     };
      //   });
      // }

      this.siteTypes = [{
        id: 1,
        name: "Tanah Kerajaan",
        count: 2,
      }, {
        id: 2,
        name: "GLC",
        count: 9,
      }, {
        id: 3,
        name: "Private Mine",
        count: 1,
      }, {
        id: 4,
        name: "River",
        count: 11,
      }, {
        id: 5,
        name: "Stockpiles",
        count: 11,
      }, {
        id: 6,
        name: "Quarry",
        count: 1,
      }]

      return;
    },
    async getSites() {
      this.isLoading = true;

      const [call, err] = await this.Helper.handle(
        this.API.get(
          `sites?${this.siteRegions
            .map((region) => `region_in=${region.id}`)
            .join("&")}`
        )
      );

      if (!err && call.status == 200) {
        this.sites = call.data.map(site => {
          return {
            id: site.id,
            name: site.name,
            site_code: site.site_code,
            site_type: site.site_type,
            region: site.region
          }
        });

        // // Disable kejap
        // call.data.forEach((site) => {
        //   const siteType = this.siteTypes.find(
        //     (type) => type.id == site.site_type?.id
        //   );

        //   if (siteType) {
        //     siteType.count += 1;
        //   } else {
        //     const undef = this.siteTypes.find(
        //       (type) => type.name == "Undefined"
        //     );

        //     if (undef) {
        //       undef.count += 1;
        //     } else {
        //       this.siteTypes.push({
        //         name: "<i class='font-weight-bold text-muted'>Undefined</i>",
        //         count: 1,
        //       });
        //     }
        //   }

        //   const siteRegion = this.siteRegions.find(
        //     (region) => region.name == site.region?.name?.replace(" Region", "")
        //   );

        //   if (siteRegion) {
        //     siteRegion.count += 1;
        //   }
        // });
      }

      return;
    },
    async initializeLiveStream() {
      this.stream.isInitialized = true;

      this.isLoadingStream = true;

      await this.loadStream();

      this.isLoadingStream = false;
    },
    streamFullscreen() {
      var elem = document.getElementById("liveStream");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    async loadStream() {
      if (this.stream.url) {
        if (flvjs.isSupported()) {
          let videoElement = document.getElementById("liveStream");

          flvjs.LoggingControl.removeLogListener(this.flvjsLogListener);

          flvjs.LoggingControl.addLogListener(this.flvjsLogListener);

          if (typeof this.flvPlayer !== "undefined") {
            if (this.flvPlayer != null) {
              this.flvPlayer.unload();
              this.flvPlayer.detachMediaElement();
              this.flvPlayer.destroy();
              this.flvPlayer = null;
            }
          }

          let mp4 = document.getElementById("mp4");

          videoElement.controls = false;

          mp4.src = null;

          this.flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.stream.url,
            hasAudio: false,
            enableStashBuffer: false,
          });

          this.flvPlayer.attachMediaElement(videoElement);

          this.flvPlayer.load();

          return;
        }
      }
    },
    flvjsLogListener(_, str) {
      if (str.includes("onSourceEnded")) {
        this.stream.isOnline = false;
      }

      if (str.includes("Received Initialization")) {
        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          this.stream.isOnline = true;

          this.flvPlayer.play();
        }
      }
    },
    async getTransactions() {
      this.isLoading = true;

      this.transactions = [];

      const _sites = this.sites.filter(site => site.site_code);

      this.selectedSite = _sites[0];

      const allPromises = [];

      for (let index = 0; index < _sites.length; index++) {
        const site = _sites[index];

        allPromises.push(new Promise(async (resolve, reject) => {
          const [call, err] = await this.Helper.handle(
            this.API.getExternal(
              `https://kssbreflex.com/cmis/index.php/api/daily_siteTransaction?kodTapak=${site.site_code.toLowerCase()}&date=${this.selectedYear}`,
              null,
              {}
            )
          )

          if (!err && call.status == 200) {
            resolve({ site: site.site_code, year: this.selectedYear, data: call.data.transaction });
          } else {
            reject(err);
          }
        }));
      }

      const result = await Promise.all(allPromises);

      this.transactions = result;

      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;

    this.$nextTick(async () => {
      if (currentYear > 2024) {
        for (let index = 2025; index <= currentYear; index++) {
          this.years.push(index);
        }
      }

      const februaryDays = moment().set('month', 1).set('year', currentYear).daysInMonth();

      this.$set(this.monthList, 1, { ...this.monthList[1], noOfDays: februaryDays });

      await this.getRegions();

      await this.getSiteTypes();

      await this.getSites();

      this.selectedYear = this.years[this.years.length - 1];
    });
  },
};
</script>

<style lang="scss">
#navSearchSite {
  max-width: 100%;
  width: 200px;
}

.site-dropdown-items {
  max-height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>